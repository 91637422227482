<template>
  <div class="chart" :style="{height:'300px',width:'100%'}" />
</template>

<script>
import * as echarts from 'echarts'
import { requestForm } from '@/assets/js/http.js'
export default {
  props: ['beginDate', 'endDate'],
  data () {
    return {
      chart: null,
      configData: {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgb(31, 35, 41)',
          textStyle: { color: 'rgb(255, 255, 255)' }
        },
        legend: {
          bottom: 0,
          left: 'center',
          data: []
        },
        series: {
          type: 'pie',
          radius: '50%',
          labelLine: {
            length: 6
          },
          itemStyle: {
            color: function (params) {
              var colorList = [
                'rgb(255, 181, 31)', 'rgb(255, 101, 66)', 'rgb(68, 135, 250)'
              ]
              return colorList[params.dataIndex]
            }
          },
          data: []
        }
      }
    }
  },
  mounted () {
    this.chart = echarts.init(this.$el)
    this.initChart()
  },
  watch: {
    beginDate () {
      this.initChart()
    },
    endDate () {
      this.initChart()
    }
  },
  methods: {
    initChart () {
      requestForm('/api/livraison/livrasion/getDemandSummary', 'post', { beginDate: this.beginDate, endDate: this.endDate }).then((res) => {
        if (res.code === '200') {
          this.configData.legend.data = res.data.title
          this.configData.series.data = res.data.list
          this.chart.setOption(
            this.configData
          )
        }
      })
    }
  }
}
</script>
